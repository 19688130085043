import * as React from "react";

import classNames from "classnames";

import Header from "../components/header";
import Footer from "../components/footer";
import Gallery from "../components/gallery";

import pic1 from "../assets/images/cornices/1.jpg";
import pic2 from "../assets/images/cornices/2.png";
import pic3 from "../assets/images/cornices/3.jpg";
import pic4 from "../assets/images/cornices/4.jpg";
import pic5 from "../assets/images/cornices/5.jpg";

import GalleryHeader from "../components/galleryheader";

const galleryColumns = [
  [pic3, pic1],
  [pic2, pic5],
  [pic4]
];

const CornicesPage = () => (
  <React.Fragment>
    <Header />
      <GalleryHeader title="Cornices" />
      <Gallery columns={galleryColumns} />
    <Footer />
  </React.Fragment>
);

export default CornicesPage;
